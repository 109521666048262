.alert.withFocus:has(.content.error) {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: brightness(50%) blur(1px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.alert .content.error {
  aspect-ratio: 1;
  min-width: 100px;
  width: fit-content;
  max-width: 50vw;
  max-height: 50vh;
  background-color: rgb(255 100 100 / 0.9);
  border: inset 4px;
  padding: 1rem;
}

.alert.withFocus:has(.content.info) {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: brightness(100%) blur(1px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.alert .content.info {
  aspect-ratio: 1;
  min-width: 100px;
  width: fit-content;
  max-width: 50vw;
  max-height: 50vh;
  background-color: rgba(35, 255, 193, 0.8);
  border: inset 4px;
  padding: 1rem;
}