.customForm {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  row-gap: 0.2rem;
}

.customForm>.formLabel {
  min-width: 10rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.customForm label::after {
  content: ":";
}

.customForm>.buttonArea {
  margin-block-start: 1rem;
  grid-column: 1 / -1;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.2rem;
}

.customForm>.fieldDescription {
  grid-column: 1 / -1;
  font-style: italic;
}

.customForm>.textField {
  padding: 0.25em;
  font-size: 1rem;
  min-width: 15rem;
  max-width: 20rem;
  width: 50%;
}

.customForm>.fieldContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.customForm>.formLabel:has(+.fieldContainer>input.modified:not(.required)),
.customForm>.fieldContainer:has(>input.modified:not(.required)),
.customForm>.formLabel:has(+input.modified:not(.required)),
.customForm>.textField.modified:not(.required),
.customForm>.selectField>.selectFieldChoice.selected {
  background-color: rgb(225 150 0 / 0.4);
}

.customForm>.formLabel:has(+.fieldContainer>input.required:not(.modified)),
.customForm>.fieldContainer:has(>input.required:not(.modified)),
.customForm>.formLabel:has(+input.required:not(.modified):not([disabled])),
.customForm>input.invalid:not([disabled]),
.customForm>input.required:not(.modified):not([disabled]),
.customForm>.formLabel:has(+.selectField.required:not(.empty):not(.modified):not(.disabled)),
.customForm>.selectField.required.empty:not(.disabled),
.customForm>.selectField.required:not(.modified):not(.disabled) {
  background-color: rgb(200 0 0 / 0.3);
}

.customForm>.selectField>.selectFieldChoice:not(.disabled):hover,
.customForm>.selectField>.selectFieldChoice.selected:hover {
  background-color: rgb(100 0 0 / 0.5);
  cursor: default;
}

.customForm>.textField[disabled]:hover,
.customForm>.selectField>.selectFieldChoice.disabled:hover {
  cursor: not-allowed;
}