:root {
  --button-color: 95 245 215;
  --button-color-hover: 36 227 189;
}

html,
body {
  font-family: Arial;
  height: 100%;
  box-sizing: border-box;
}

html:has(.withFocus) {
  overflow: hidden;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.contentContainer {
  display: contents;
}

.mainContent {
  display: flex;
  flex: auto;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
}

.mainContent .otherComponents {
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  padding-block: 1em;
}

.mainContent>div {
  width: 100%;
}

.loadingCanvas {
  background-color: #fff;
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
}

.loadingContent {
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
  text-align: center;
  font-size: 2rem;
}

.loadingContent span.indicator {
  color: #2aa198;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: underline;
}

.mainNav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.mainNav a {
  text-transform: uppercase;
  background-color: #89d3fa;
  padding: 4px;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  border-width: 5px;
  border-color: rgba(0, 0, 0, 0);
}

.mainNav a:hover {
  background-color: #57bff7;
  text-decoration: none;
}

.mainNav a.active {
  text-decoration: underline;
}

.videoNav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 1em;
  row-gap: 1em;
  flex-wrap: wrap;
}

.videoNav a {
  text-transform: uppercase;
  background-color: rgb(var(--button-color));
  padding: 4px;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  border-width: 5px;
  border-color: rgba(0, 0, 0, 0);
}

.videoNav a:hover {
  background-color: rgb(var(--button-color-hover));
}

.videoNav a.active {
  text-decoration: underline;
}

.companyLogo {
  width: 10%;
}

.largeLandingPhoto {
  width: 50%;
}

.textLink {
  text-decoration-line: underline;
}

.textLink:hover {
  color: #0059ff;
}

.landingLinks {
  display: inline-flex;
  margin: 1em;
  padding: 1em;
  background-color: #5ff5d7;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.landingLinks:hover {
  background-color: #1fe0ba;
}

button {
  padding: 4px;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-width: 5px;
  border-color: rgba(0, 0, 0, 0);
  background-color: #333;
  color: #fff;
  width: max-content;
}

button:hover {
  background-color: rgb(5, 185, 125);
  color: #fff;
}

button[disabled] {
  background-color: #666;
  box-shadow: none;
  cursor: not-allowed;
}

.studentTable {
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #333;
  width: 100%;
}

thead th:nth-child(1) {
  width: 20%;
}

thead th:nth-child(2) {
  width: 20%;
}

thead th:nth-child(3) {
  width: 40%;
}

thead th:nth-child(4) {
  width: 20%;
}

tbody tr:nth-child(1) {
  width: 20%;
}

tbody tr:nth-child(2) {
  width: 20%;
}

tbody tr:nth-child(3) {
  width: 40%;
}

tbody tr:nth-child(4) {
  width: 20%;
}

td {
  text-align: center;
}

th,
td {
  padding: 1em;
}

.reminderBanner {
  background-color: #1fe0ba;
  margin: 1em 0;
  padding: 6px;
  border: 2px solid #000;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.landingBlurb {
  width: 50%;
}

.flyers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}

.flyer {
  width: min-content;
  height: min-content;
}

.flyer:hover {
  filter: brightness(75%);
  cursor: zoom-in;
}

.flyer * {
  pointer-events: none;
}

.flyer.withFocus {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: brightness(50%) blur(1px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flyer.withFocus:hover {
  filter: none;
  cursor: zoom-out;
}

.flyer.withFocus * {
  pointer-events: all;
}

.extraLightMarginRight {
  margin-right: 0.5em;
}

.moderateMarginTop {
  margin-top: 4em;
}

.moderateMarginTop div {
  margin-top: 1em;
}

.heavySideMargins {
  margin-left: 4em;
  margin-right: 8em;
}

.lightMarginTop {
  margin-top: 1em;
}

.centered {
  text-align: center;
}

.bolded {
  font-weight: bold;
}

.underlined {
  text-decoration: underline;
}

.capitalized {
  text-transform: capitalize;
}

.errorText {
  color: #fc0000;
}

.adminPanel {
  flex: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.2em 0;
  border-block-end: 1px solid #000;
}

.adminPanel>label {
  margin-inline-start: 0.1rem;
  margin-inline-end: 0.1rem;
  padding: 0.1rem 0.4rem;
  border-inline-start: 1px solid #000;
  border-inline-end: 1px solid #000;
  border-radius: 5% / 50%;
  text-align: center;
  min-width: max-content;
}

.adminBlock {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 0.2rem;
  padding-inline-end: 0.2rem;
  border-inline-end: 1px solid #000;
  min-width: max-content;
}

.adminPanel>.adminBlock:last-of-type {
  border-inline-end: none;
}

.adminBlock>label {
  margin-inline-start: 0.1rem;
  margin-inline-end: 0.1rem;
  padding: 0.1rem 0.2rem;
}

.adminBlock>label::after {
  content: ":";
}

.adminBlock>.optionBlock {
  display: inline-block;
  margin-inline-start: 0.1rem;
  margin-inline-end: 0.1rem;
  padding: 0.1rem 0.2rem;
  cursor: default;
}

.adminBlock>.optionBlock.isSelected {
  background-color: rgb(var(--button-color) / 0.75);
}

.adminBlock>.optionBlock.interactive:hover {
  background-color: rgb(var(--button-color-hover) / 0.75);
}

/* MOBILE-SPECIFIC */
@media only screen and (max-width: 767px) {
  body {
    padding: 0em 3em 0em 1em;
  }

  nav {
    text-align: center;
  }

  p {
    font-size: 0.75rem;
  }

  a {
    display: block;
  }

  .companyLogo {
    width: 20%;
  }

  .largeLandingPhoto {
    width: 100%;
  }

  .landingBlurb {
    width: 100%;
  }

  .adminPanel {
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    border-block-end: 1px solid #000;
  }

  .adminBlock {
    border-inline-end: none;
    margin-block-start: 0.2em;
  }
}

/* MOBILE-LANDSCAPE */
@media only screen and (max-width: 767px) and (orientation: landscape) {
  body {
    padding: 0em 3em 0em 1em;
  }

  nav {
    text-align: center;
  }

  p {
    font-size: 0.75rem;
  }

  .companyLogo {
    width: 20%;
  }

  .largeLandingPhoto {
    width: 100%;
  }

  .landingBlurb {
    width: 100%;
  }

}

/* TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body {
    padding-left: 1em;
    padding-right: 3em;
  }

  p {
    font-size: 1rem;
  }

  .companyLogo {
    width: 20%;
  }

  .largeLandingPhoto {
    width: 100%;
  }

  .landingBlurb {
    width: 100%;
  }

}

/* DESKTOP-FRIENDLY FOR MOST LAPTOPS */
@media only screen and (min-width: 1025px) {
  body {
    padding-left: 4em;
    padding-right: 6em;
  }

  p {
    font-size: 1rem;
  }
}

/* DESKTOP-FRIENDLY FOR VERY LARGE SCREENS */
@media only screen and (min-width: 2000px) {
  body {
    padding-left: 4em;
    padding-right: 6em;
  }

  p {
    font-size: 1.5rem;
  }

}